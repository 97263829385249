form {
  flex-direction: column;
}

.formElement {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.formElement:last-child {
  margin-bottom: 0;
}

.input,
.select {
  display: flex;
  position: relative;
  z-index: 1;
  color: var(--colors-black);
  flex-direction: row;
  justify-content: space-between;
  pointer-events: all;
  margin-top: 0px;
  margin-left: 0px;
  padding: 8px 12px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--colors-midgray);
  border-radius: 4px;
  outline: none;
  align-items: center;
  gap: 12px;
  font-size: var(--size-small);
}
.input:focus-within,
.select:focus-within {
  border-color: var(--colors-blue);
  -webkit-box-shadow: 0px 0px 0px 1px rgb(10 44 202 / 90%);
  box-shadow: 0px 0px 0px 1px rgb(10 44 202 / 90%);
  border-width: 1px;
}

.area {
  composes: input;
}
textarea {
  resize: none;
  font-family: inherit;
}

.error {
  border-color: var(--colors-red);
  -webkit-box-shadow: 0px 0px 0px 1px var(--colors-red);
  box-shadow: 0px 0px 0px 1px var(--colors-red);
  border-width: 1px;
}

.input > input,
.select > select {
  display: flex;
  flex-grow: 1;
  outline: none;
  border: none;
}

.input > textarea {
  min-height: 180px;
  width: 100%;
  outline: none;
  border: none;
}

.adornment {
  display: flex;
  cursor: pointer;
}
.adornment.start {
  margin-right: auto;
}
.adornment.end {
  margin-left: auto;
}
.adornment .autoHide {
  opacity: 0;
  pointer-events: none;
}
.adornment .disabled {
  pointer-events: none;
  user-select: none;
  cursor: none;
}

.label {
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--colors-black);
  font-weight: 400;
  font-size: var(--size-small);
}
.subLabel {
  display: flex;
  flex-direction: row;
  font-size: small;
  color: var(--colors-darkgray);
  margin: 0;
  margin-bottom: 4px;
}

.button {
  position: relative;
  border: none;
  justify-content: center;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-size: var(--size-small);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  transition: background-color 0.1s ease-in, transform 0.2s ease;
}

.button:active {
  transform: translate(1px, 1px);
}
.button .icon {
  margin-left: 6px;
  display: flex;
}

.button.primary {
  transition: box-shadow 0.1s ease-in;
  background-color: var(--colors-green);
  color: var(--colors-blue);
}
.button.primary:hover {
  -webkit-box-shadow: 2px 2px 0px 1px var(--colors-blue);
  box-shadow: 2px 2px 0px 1px var(--colors-blue);
}
.button.secondary {
  border: 2px solid var(--colors-blue);
  background-color: var(--colors-white);
  color: var(--colors-blue);
}
.button.secondary:hover {
  background-color: var(--colors-lightblue);
}
.button.tertiary {
  background-color: var(--colors-blue);
  color: var(--colors-white);
}
.button.tertiary:hover {
  background-color: var(--colors-darkblue);
}

.button.warning {
  background-color: transparent;
  color: var(--colors-red);
}
.button.warning:hover {
  background-color: var(--colors-lightgray);
}
.button.danger {
  background-color: var(--colors-red);
  color: #fff;
}
.button.danger:hover {
  background-color: var(--colors-darkred);
}

.button.link {
  background-color: transparent;
  color: var(--colors-blue);
}
.button.link:hover {
  text-decoration: underline;
}

.button.disabled {
  pointer-events: none;
  background-color: var(--colors-midgray);
  color: var(--colors-white);
  user-select: none;
}
.button.secondary.disabled {
  background-color: var(--colors-white);
  border: 2px solid var(--colors-midgray);

  color: var(--colors-midgray);
}
.button.warning.disabled {
  background-color: transparent;
  color: var(--colors-darkgray);
}

.button.icon {
  padding: 0;
  background-color: transparent;
}

.kickstartIcon {
  flex-grow: 0;
  position: relative;
}
.button.kickstart {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 2px 2px 0 1px var(--colors-orange);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  transition: background-color 0.6s ease, box-shadow 0.4s ease;
}
.button.kickstart:hover,
.button.kickstart:active {
  background-color: var(--colors-lightblue);
}
.button.kickstart:active {
  box-shadow: 2px 2px 0 1px transparent;
}

.button.kickstart .title {
  color: var(--colors-black);
  margin-left: 8px;
  font-weight: 600;
}
.button.kickstart .kickstartLabel {
  display: flex;
  color: var(--colors-blue);
  justify-content: flex-end;
  flex-grow: 1;
  font-weight: 600;
  align-items: center;
}
.vcenter {
  align-items: center;
}
.finished.button.kickstart {
  background-color: #536bda;
  box-shadow: none;
  pointer-events: none;
}
.finished.button.kickstart * {
  color: #fff;
}
.finished.button.kickstart .kickstartLabel {
  display: none;
}
